<template>
    <div class="form-container">
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(askContact)" v-if="!loading">
                <div class="form-row">
                    <div class="form-col">
                        <ValidationProvider  class="input-container"  rules="required|max:100" v-slot="{ errors }">
                            <input type="text" :class="{ 'input-error' : errors[0]}" placeholder="First Name*" v-model="firstname" />
                            <span>{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                    <div class="form-col">
                        <ValidationProvider  class="input-container"  rules="required|max:100" v-slot="{ errors }">
                            <input type="text" :class="{ 'input-error' : errors[0]}"  placeholder="Last Name*" v-model="lastname" />
                            <span>{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-col">
                         <ValidationProvider  class="input-container"  rules="required|max:100" v-slot="{ errors }">
                            <input type="text" placeholder="Company*" :class="{ 'input-error' : errors[0]}" v-model="company" />
                            <span>{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                    <div class="form-col">
                         <ValidationProvider  class="input-container"  rules="max:100" v-slot="{ errors }">
                            <input type="text" placeholder="Job" :class="{ 'input-error' : errors[0]}" v-model="job" />
                            <span>{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
        
                </div>   
                <div class="form-row">
                    <div class="form-col">
                         <ValidationProvider  class="input-container"  rules="max:100" v-slot="{ errors }">
                            <input type="text" placeholder="City" :class="{ 'input-error' : errors[0]}" v-model="city" />
                            <span>{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                    <div class="form-col">
                         <ValidationProvider  class="input-container"  rules="max:100" v-slot="{ errors }">
                            <input type="text" placeholder="Country" :class="{ 'input-error' : errors[0]}" v-model="country" />
                            <span>{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
        
                </div> 
                <div class="form-row">
                    <div class="form-col">
                        <ValidationProvider  class="input-container"  rules="required|max:100|email" v-slot="{ errors }">
                            <input type="text" :class="{ 'input-error' : errors[0]}"  placeholder="Email*" v-model="email" />
                            <span>{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                    <div class="form-col">
                        <ValidationProvider  class="input-container"  rules="max:100" v-slot="{ errors }">
                            <input type="text" :class="{ 'input-error' : errors[0]}" placeholder="Phone" v-model="phone" />
                            <span>{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div> 
                <div class="form-row">
                    <ValidationProvider  class="input-container"  rules="max:500|required" v-slot="{ errors }">
                        <textarea v-model="message" :class="{ 'input-error' : errors[0]}" placeholder="Message*" rows="8"></textarea>
                        <span>{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>
                <div class="form-row form-submit">
                    <input type="submit" class="btn-form" value="Send" />
                </div>
                <div class="notif success" v-if="succes">
                    <p>Your message has been sent successfully.<br />You will be contacted by our team shortly.</p>
                </div>
                <div v-if="error" class="notif error">
                    <p>{{error}}</p>
                </div>
            </form>
            <div v-else class="loader-container">
                <Loader></Loader>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
import Loader from './Loader'
import { ValidationProvider,  ValidationObserver } from 'vee-validate'
import axios from 'axios'

export default {
    name : "FormContact",
    components : {
        ValidationProvider,
        ValidationObserver,
        Loader
    },
    data() {
        return {
            firstname : null,
            lastname : null,
            company : null,
            phone : null,
            email : null,
            city : null,
            country : null,
            job : null,
            message: null,
            error : null,
            succes : false,
            loading: false
        }
    },
    methods : {
        askContact() {
            this.loading = true
            this.error = null
            this.succes = false

            let formData = new FormData();

            formData.append('firstname', this.firstname)
            formData.append('lastname', this.lastname)
            formData.append('email', this.email)
            formData.append('message', this.message)
            formData.append('company', this.company)
            formData.append('from', this.$route.name)
            if(this.phone) {
                formData.append('phone', this.phone)
            }            
            if(this.company) {
                formData.append('city', this.city)
            }
            if(this.country) {
                formData.append('country', this.country)
            }
            if(this.job) {
                formData.append('job', this.job)
            }

            let config = {
                validateStatus: () => true,
            };
            let vm = this

            axios
                .post('https://cryptovirtua.com/mailer/sendmail.php', formData, config)
                .then(function (res) {
                    vm.loading = false
                    if (res.status == 400) {
                        vm.error = 'Please fill out all the mandatory fields.'
                    }else if(res.status == 500) {
                        vm.error = 'A server error has occured. Please try again later.'
                    
                    }else if(res.status == 200) {
                            vm.succes = true
                            vm.firstname = null
                            vm.lastname = null
                            vm.company = null
                            vm.phone = null
                            vm.email = null
                            vm.message = null
                            vm.city = null
                            vm.country = null
                            vm.job = null
                        }else {
                            vm.error = 'An error has occured. Please try again.'
                        }
                    }
                )     
        } 
    }
}
</script>

<style lang="scss" scoped>
    .form-container {
        width: 1000px;
        max-width: 90%;
        margin:0 auto;
    }

    .form-container span:first-of-type {
        display: flex;
        flex-direction: column; 
        width: 100%;
    }
    
    form {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .form-row {
        width:100%;
        display: flex;
        justify-content: center;
        margin:10px 0;
    }

    .form-col {
        width:50%;
        display: flex;
        justify-content: center;
    }

    input, textarea {
        background:#fff;
        color:$text-color;
        padding:12px 20px;
        font-size:15px;
        font-weight:400;
        border-radius:10px;
        border: 2px solid #fff;
        // width:calc(100% - 40px);
        margin:0 10px;
        font-family: $font;
        // -webkit-box-shadow:inset 0px 5px 5px 1px $text-color;
        // -moz-box-shadow:inset 0px 5px 10px 1px $text-color;
        // box-shadow:inset 0px 5px 10px 1px $text-color;
    }

    input:focus, textarea:focus {
        outline: none;
        border:2px solid $main-color;
    }

    input::placeholder, textarea::placeholder {
        color:$text-color;
        font-family: $font;
    }

    .input-container {
        flex-direction: column;
    }

    .input-container span {
        color:$red;
        font-size:12px;
        margin-left: 30px;
    }

    input.input-error, textarea.input-error {
        border-color:$red;
    }

    input.input-error::placeholder, textarea.input-error::placeholder {
        color:$red;
    }

    .btn-form {
        width:200px;
        height:60px;
        border:1px solid $main-color;
        border-radius: 10px;
        background-color: $main-color;
        color:#fff;
        cursor: pointer;
        transition: 0.5s;
        font-size:20px;
        font-weight: 400;
        letter-spacing: 1px;
    }

    .btn-form:hover {
        background-color: #fff;
        color:$main-color;
    }

    .loader-container {
        display: flex;
        justify-content: center;
        height: 300px;
        align-items: center;
    }

    .notif {
        padding:10px 20px;
        border-radius:10px;
    }

    .notif.success {
        border:1px solid $succes;
    }

    .notif.success p {
        color:$succes;
    }

    .notif.error {
        border:1px solid $red;
    }

    .notif.error p {
        color:$red;
    }

    .notif p {
        text-align: center;
    }

    @media screen and (max-width: 768px) {
        .form-col {
            width:100%;            
        }

        .form-row:not(.form-submit) {
            flex-direction: column;
            margin: 0;
        }

        .input-container {
            margin:10px 0;
        }

        .btn-form {
            width: 150px;
            height: 50px;
            font-size:18px;
        }
    }


</style>